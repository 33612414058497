<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-lead-order-submenu has-demo v-on:demo="startDemo()"></q-agent-lead-order-submenu>
        <v-row class="mx-5">
            <v-col cols=12 class="text-center">
                <q-moloney-demand-report-datatable></q-moloney-demand-report-datatable>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QMoloneyDemandReportDatatable from '@/components/datatables/LeadOrders/QMoloneyDemandReportDatatable.vue';
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';

export default {
    data() {
        return {
            //search: '',
        }
    },
    components: {
        QMoloneyDemandReportDatatable,
        QAgentLeadOrderSubmenu,
        
    },
    methods: {

    },

}

</script>
