<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-1 pl-7 pb-0 pt-0 mt-0 mb-0" id="datatable">Leads</p>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Leads
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters" :id="entity + '_datatable'">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table dense v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 nowrap" :footer-props="{itemsPerPageOptions:[100,200,300]}" :loading="loading" :options.sync="options">
                <template v-slot:item.AGENTNAME="{ item }">
                    <a class="nowrap" :to="{ name: 'AgentDetail', params: { agentcode: item.AGENTCODE} }">{{item.AGENTNAME}}</a>
                </template>
                <template v-slot:item.FULL_NAME="{ item }">
                    <span class="nowrap">{{item.FULL_NAME}}</span>
                </template>
                <template v-slot:item.PRODUCT_TYPE="{ item }">
                    <span class="nowrap">
                        <span v-if="item.MAPPED_PRODUCT_TYPE != '!'">{{item.PRODUCT_TYPE}}</span>
                        <span v-else class="red--text"><strong>!!!{{item.PRODUCT_TYPE}}!!!</strong></span>
                    </span>
                </template>
                <template v-slot:item.AGENTID="{ item }">
                    <v-text-field dense hide-details outlined v-model="item.AGENTID"></v-text-field>
                </template>
                <template v-slot:item.MODIFED_TIME="{ item }">
                    <span class="nowrap">{{ formatDateTime (item.MODIFED_TIME)}}</span>
                </template>
                <template v-slot:item.ZOHO_ID="{ item }">
                    <a target="_tab" :href="'https://crmplus.zoho.com/quility7/index.do/cxapp/crm/org710011527/tab/Leads/' + item.ZOHO_ID">{{item.ZOHO_ID}}</a>
                </template>
                <template v-slot:item.ALLOCATION_LOGIC="{ item }">
                    <v-btn small @click="viewLogic(item)">Logic</v-btn>
                </template>
                <template v-slot:item.ORDER_NUMBER="{ item }">
                    <span v-if="item.ORDER_NUMBER" class="nowrap">{{item.ORDER_NUMBER}}
                        <v-btn icon @click="viewOrder(item.ORDER_ID)">
                            <v-icon right>fas fa-arrow-alt-circle-down</v-icon>
                        </v-btn>
                    </span>
                </template>
            </v-data-table>
        </v-card>
        <v-bottom-sheet v-model="bottom_sheet" scrollable>
            <v-card height="300">
                <v-card-text>
                    <v-btn color="red" @click="bottom_sheet = false" absolute small fab top right>
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                    <q-lead-order-details :order-id="current_lead_order_id"></q-lead-order-details>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
        <v-dialog v-model="show_logic">
            <v-card>
                <v-card-text>
                    <pre>
                        {{current_logic}}
                    </pre>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import QLeadOrderDetails from "./QLeadOrderDetails.vue"
import state_options from '@/store/StateOptions.js'
export default {
    name: "QLeadAllocationReviewDataTable",
    mixins: [QDataTableMixin],
    props: ['leads'],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead',
            filters: { 'State': "-All-", 'County': "-All-", 'Product': "-All-" },
            options: { sortBy: ['AssignDate'], sortDesc: ["true"] },
            bottom_sheet: false,
            current_lead_order_id: null,
            current_logic: null,
            show_logic: false
        }
    },
    mounted: function() {

    },
    computed: {
        filter_keys: function() {
            return ['State', 'County', 'Product']
        },
        all_data: function() {
            if (typeof this.leads == "undefined") {
                return [];
            }
            var matches = this.filterFor("STATE", this.filters.State, this.leads);
            matches = this.filterFor("COUNTY", this.filters.County, matches);
            matches = this.filterFor("PRODUCT_TYPE", this.filters.Product, matches);
            return matches;
        },
        is_valid: function() {
            return true;
        },
        the_headers: function() {
            return [{
                    text: 'Order #',
                    value: 'ORDER_NUMBER',
                    sortable: true,
                }, {
                    text: 'OptID',
                    value: 'AGENTID',
                    align: 'start',
                    sortable: true,
                    width: "170"
                }, {
                    text: 'Agent Name',
                    value: 'AGENTNAME',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'Product',
                    value: 'PRODUCT_TYPE',
                    sortable: true,
                },
                {
                    text: 'County',
                    value: 'COUNTY',
                    sortable: true,
                },
                {
                    text: 'State',
                    value: 'STATE',
                    sortable: true,
                },
                /*{
                    text: 'Zip',
                    value: 'ZIP_CODE',
                    sortable: true,
                },*/
                {
                    text: 'Email',
                    value: 'EMAIL',
                    sortable: true,
                },
                {
                    text: 'Name',
                    value: 'FULL_NAME',
                    sortable: true,
                },
                /*{
                    text: 'Lead Status',
                    value: 'LEAD_STATUS',
                    sortable: true,
                }, {
                    text: 'Lead Type',
                    value: 'LEAD_TYPE',
                    sortable: true,
                },*/
                {
                    text: 'Zoho ID',
                    value: 'ZOHO_ID',
                    sortable: true,
                },
                /* {
                    text: 'Modified',
                    value: 'MODIFED_TIME',
                    sortable: true,
                }, */
                {
                    text: 'Date',
                    value: 'MODIFIED_TIME',
                    sortable: true,
                },
                {
                    text: 'Logic',
                    value: 'ALLOCATION_LOGIC',
                    sortable: true,
                }
            ]
        },
        available_filters: function() {
            return {
                "State": [...["-All-"], ...state_options],
                "County": [...["-All-"], ...this.county_options],
                "Product": [...["-All-"], ...this.product_options],
            };
        },
        county_options: function() {
            var g = this
            if (this.filters.State != "-All-") {
                //filtered for state
                var o = this.leads.filter(function(item) {
                    return item.STATE == g.filters.State
                })
            } else {
                //return copy of all leads
                var o = this.leads.filter(function(item) {
                    return true
                })
            }
            var counties = [];
            o.forEach(function(l) {
                if (counties.indexOf(l.COUNTY) == -1) {
                    counties.push(l.COUNTY);
                }
            })
            return counties.sort();
        },
        product_options: function() {
            var g = this
            var products = [];
            this.leads.forEach(function(l) {
                if (products.indexOf(l.PRODUCT_TYPE) == -1) {
                    products.push(l.PRODUCT_TYPE);
                }
            })
            return products.sort();
        }
    },
    methods: {
        goToTop: function() {
            this.$vuetify.goTo('#lead_datatable', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        viewOrder: function(order_id) {
            this.bottom_sheet = true
            this.current_lead_order_id = order_id;
        },
        viewLogic: function(order) {
            this.current_logic = order.ALLOCATION_LOGIC
            this.show_logic = true;
        },
        filterFor(key, v, data) {
            var d = []
            if (v == "-All-") {
                return data
            }
            data.forEach(function(item) {
                if (item[key] == v) {
                    d.push(item)
                }
            })
            return d;
        },
        reset() {
            this.filters = { 'State': "-All-", 'County': "-All-", 'Product': "-All-" };
            this.bottom_sheet = false;
            this.current_lead_order_id = null;
            this.current_logic = null;
            this.show_logic = false;
        }
    },
    watch: {

    },
    components: {
        QLeadOrderDetails
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}

</style>
