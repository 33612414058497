<template>
    <div>
        <v-card flat id="lead_order_fillrate">
            <v-container fluid class="ma-0 pa-0 q_leads_1">
                <v-row>
                    <v-col cols="12" sm="8" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7 text-left">
                        <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">The Moloney Fill Rate Dashboard
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <!--<v-col cols="12" sm="4" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>-->
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start">
                <q-filters dark ref="filters" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :show-reset="true">
                </q-filters>
                <v-switch v-model="true_requests" :label="true_requests ? 'True Totals' : 'Raw Totals'"></v-switch>
            </v-row>
            <v-progress-linear indeterminate v-if="data_loading"></v-progress-linear>
            <!--<v-row class="ml-5 mb-3" v-if="false">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-row>-->
            <h2>{{ formatReadableDate(filters.Weeks.startDate) }} to {{ formatReadableDate(filters.Weeks.endDate) }}</h2>
            <v-row class="d-flex justify-start" v-if="true_requests">
                <v-col cols="6">
                    <v-card class="scorecard-card">
                        <div :class="'pa-1 pt-2 title-head leads'">
                            Leads
                        </div>
                        <v-card-text>
                            <h1>{{ formatPercentage(the_data.FilledLeads / the_data.RequestedLeads) }}</h1>
                            <p>Requested: {{ the_data.RequestedLeads }}</p>
                            <p>Filled: {{ the_data.FilledLeads }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="scorecard-card">
                        <div :class="'pa-1 pt-2 title-head leads'">
                            Orders
                        </div>
                        <v-card-text>
                            <h1>{{ formatPercentage(the_data.FilledOrders / the_data.Orders) }}</h1>
                            <p>Orders: {{ the_data.Orders }}</p>
                            <p>Fulfilled: {{ the_data.FilledOrders }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6" :key="product_type" v-for="(product, product_type) in the_data.Products">
                    <v-card class="scorecard-card">
                        <div :class="'pa-1 pt-2 title-head leads'">
                            {{ product_type }}
                        </div>
                        <v-card-text>
                            <h1>{{ formatPercentage(product.FilledLeads / product.RequestedLeads) }}</h1>
                            <p>Requested: {{ product.RequestedLeads }}</p>
                            <p>Filled: {{ product.FilledLeads }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-start" v-else>
                <v-col cols="6">
                    <v-card class="scorecard-card">
                        <div :class="'pa-1 pt-2 title-head leads'">
                            Leads
                        </div>
                        <v-card-text>
                            <h1>{{ formatPercentage(the_data.FilledLeads / the_data.RawRequestedLeads) }}</h1>
                            <p>Raw Requested: {{ the_data.RawRequestedLeads }}</p>
                            <p>Filled: {{ the_data.FilledLeads }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="scorecard-card">
                        <div :class="'pa-1 pt-2 title-head leads'">
                            Orders
                        </div>
                        <v-card-text>
                            <h1>{{ formatPercentage(the_data.FilledOrders / the_data.Orders) }}</h1>
                            <p>Orders: {{ the_data.Orders }}</p>
                            <p>Fulfilled: {{ the_data.FilledOrders }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6" :key="product_type" v-for="(product, product_type) in the_data.Products">
                    <v-card class="scorecard-card">
                        <div :class="'pa-1 pt-2 title-head leads'">
                            {{ product_type }}
                        </div>
                        <v-card-text>
                            <h1>{{ formatPercentage(product.FilledLeads / product.RawRequestedLeads) }}</h1>
                            <p>Raw Requested: {{ product.RawRequestedLeads }}</p>
                            <p>Filled: {{ product.FilledLeads }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');
import QFilters from '@/components/utils/QFilters.vue'
import { weekdiff } from '@/components/utils/BusinessTimeDateDiff.js'
import moment from 'moment'

export default {
    name: "QMoloneyFillrateReport",
    props: [],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead_order',
            //use this to "preset" a filter
            filters: {
                'LeadType': "Lighthouse",
                "Weeks" : null,
            }, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': ['OrderDate'], //used to set the default sorting column
                'sortDesc': [false],
            },
            the_data: [], //this will hole the data fromt he server now that we aren't using vuex anymore.
            search: null,
            selected: [],
            data_loading: false,
            available_data_filters : null,
            true_requests : true
        }
    },
    mounted: function() {
        this.$nextTick(() => {this.filters.Weeks = this.available_filters.Weeks[0].value; this.filters.LeadType = 'Lighthouse'});         
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        filter_keys: function() {
            return ['LeadType', 'Weeks']; //this must sync up with availableFilters in the Model in Laravel.
        },
        available_filters: function() {
            var weeks = this.getAvailableBusinessDates('weeks', false, true, '2022-07-01');
            weeks.shift();
            var week_selection = [];
            weeks.forEach((w) => {
                week_selection.push({
                    text : w.label,
                    value : {
                        endDate : w.endDateFormat,
                        startDate : w.startDateFormat,
                        'disableMultiple' : true
                    }
                })
            })
            var day_of_the_week = moment().format('dddd');
            var current = moment().add(7, 'days').subtract(weekdiff[day_of_the_week]['friday'], 'days');
            week_selection.unshift({
                text: moment(current).subtract(6, 'days').format('ddd MMM Do') + " - " + moment(current).format('ddd MMM Do'),
                value : {
                    endDate : moment(current).format('YYYY-MM-DD'),
                    startDate : moment(current).subtract(6, 'days').format('YYYY-MM-DD'),
                    'disableMultiple' : true
                }
            })
            var lead_types = ['Lighthouse', 'Recruiting', 'Call In', 'Debt Free Life', 'Economy']
            return {
                "LeadType": lead_types,
                "Weeks" : week_selection,
            };
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            this.data_loading = true
            var filters = {
                LeadType: this.filters.LeadType,
                startDate: this.filters.Weeks.startDate,
                endDate: this.filters.Weeks.endDate,
                trueTotals : this.filters['True Totals_switch']
            }
            var g = this;
            QuilityAPI.getMoloneyFillrateReportData(filters, opt).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
            }).catch(function(error) {
                //g.showError("There was a problem getting the data.", error);
                //g.data_loading = false
            });
        }, 200),

        exportResults: function() {
            this.is_exporting = true
            this.refreshData();
        },
        refresh: function() {
            this.the_data = []
            this.refreshData();
        },
        refreshData: function(search) {
            var opt = {
                ...this.options,
            }
            this.expanded = [];
            this.loadData(opt);
        },
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start text-center"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
        exportResults: function() {
            var fields = this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.the_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify('MoloneyReportExport_' + this.formatDate());
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("Moloney Demand Export", { "leaderboard": this.title })
            } catch (err) {
                console.error(err);
            }

        },
        sumField(key) {
            return this.all_data.reduce((a, b) => a + (Number(b[key]) || 0), 0)
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
                this.$nextTick(this.refreshData);
            }
        },
        'filters.LeadType': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'options': {
            deep: true,
            handler(newV) {
                /*var g = this;
                if (!this.firstPull) {
                    this.$vuetify.goTo('#' + this.entity + '_datatable', {
                        duration: 250,
                        easing: 'easeOutCubic',
                    });
                }
                this.$nextTick(g.refreshData)
                */
            }
        },
    },
    components: {
        QFilters,
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.grey_row {
    background-color: #f7f7f7;
}

.lead-order-table {
    font-size: 12px;
}

.lead-order-line-table {
    background-color: #f7f7f7;
}

.lead-order-line-table table td {
    font-size: .9em;
    width: 10%;
}
</style>
<style>
.lead-divider {
    margin: 0 6px;
}
</style>