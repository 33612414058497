<template>
    <div>
        <v-card flat id="lead_order_datatable">
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="8" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7 text-left">
                        <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">The Moloney Weekly Demand Report
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="4" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start">
                <q-filters dark ref="filters" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :show-reset="true">
                </q-filters>
            </v-row>
            <v-row class="ml-5 mb-3" v-if="false">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0 lead-order-table" :footer-props="{itemsPerPageOptions:[50, 100, 200, 300]}" :loading="loading" :options.sync="options">
                <template v-slot:item.State="{ item }">
                    <span>
                        {{ item.State }}
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="item.HasOrphanOrderState" small v-on="on">fas fa-exclamation-triangle</v-icon>
                                    </template>
                                    <span>This state has an order with only this state selected.</span>
                                </v-tooltip>
                    </span>
                </template>
                <template slot="body.append">
                    <tr>
                        <th class="title text-center" v-for="(header,ix) in the_headers" :key="ix">
                            <span v-if="header.value == 'State'">
                                <v-btn v-if="the_data.length > 0" color="secondary" @click="exportResults">
                                    Download <v-icon>fas fa-download</v-icon>
                                </v-btn>
                            </span>
                        </th>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');
import QFilters from '@/components/utils/QFilters.vue'

export default {
    name: "QMoloneyDemandReporDatatable",
    props: [],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead_order',
            //use this to "preset" a filter
            filters: {
                'LeadType': "Lighthouse",
                "OrderType": "-All-",
                "ProductType": "-All-",
            }, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': ['OrderDate'], //used to set the default sorting column
                'sortDesc': [false],
            },
            the_data: [], //this will hole the data fromt he server now that we aren't using vuex anymore.
            search: null,
            selected: [],
            data_loading: false,
        }
    },
    mounted: function() {
        //this.$refs.filters.show = true
        this.refreshData()
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['LeadType', 'OrderType', 'ProductType']; //this must sync up with availableFilters in the Model in Laravel.
        },
        total_items: function() {
            return this.the_data.length;
        },
        the_headers: function() {
            var r = [{
                text: 'State',
                value: 'State',
                align: "left"
            }, {
                text: 'Quantity Requested',
                value: 'MaxRequestedQty',
                align: "center"
            }, {
                text: 'Quantity Filled',
                value: 'MaxFilledQty',
                align: "center"
            }, {
                text: '# Leads Needed (Inflated)',
                value: 'MaxNeededInflatedQty',
                align: "center"
            },
            {
                text: '# Leads Needed (Actual)',
                value: 'MaxNeededActualQty',
                align: "center"
            },{
                text: 'Overflow',
                value: 'OverflowQty',
                align: "center"
            }, ]

            return r;
        },

        available_filters: function() {
            return {
                "OrderType": ["-All-", "Standing Order", "One-time Order"],
                "LeadType": ["Lighthouse", "DX", "Quility Digital Lead", "Recruiting", "Economy"],
                "ProductType": [
                    { "text": "-All-", value: "-All-" },
                    { "text": "Mortgage Protection (LHMP)", value: "LHMP" },
                    { "text": "Life Insurance (LHGL)", value: "LHGL" },
                    { "text": "Final Expense (LHFL)", value: "LHFE" },
                    { "text": "Debt Free Life (LHDFL)", value: "LHDFL" },
                    { "text": "Retirement Solutions (LHRS)", value: "LHRS" },
                    { "text": "Infinite Banking (LHIB)", value: "LHIB" },
                    { "text": "Tax-Free Retirement (LHIUL)", value: "LHIUL" },
                    { "text": "Term Life", value: "TL" },
                    { "text": "Mortgage Protection", value: "MP" },
                    { "text": "Mortgage Protection / Term Life", value: "MP/TL" },
                    { "text": "Debt Free Life", value: "DFL" },
                    { "text": "Recruiting", value: "REC" }
                ]
            };
        },
        export_fields: function() {
            return ['State',
                'MaxRequestedQty',
                'MaxFilledQty',
                'MaxNeededQty',
                'NumLeadOrderLines',
            ]
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            this.data_loading = true
            var filters = {
                OrderType: this.filters.OrderType,
                LeadType: this.filters.LeadType,
                ProductType: this.filters.ProductType,
                OrderStatus: "Open"
            }
            var g = this;
            QuilityAPI.getMoloneyDemandReportData(this.agentCode, filters, opt).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
            }).catch(function(error) {
                //g.showError("There was a problem getting the data.", error);
                //g.data_loading = false
            });
        }, 200),

        exportResults: function() {
            this.is_exporting = true
            this.refreshData();
        },
        refresh: function() {
            this.the_data = []
            this.refreshData();
        },
        refreshData: function(search) {
            var opt = {
                ...this.options,
            }
            this.expanded = [];
            this.loadData(opt);
        },
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start text-center"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
        exportResults: function() {
            var fields = this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.the_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify('MoloneyReportExport_' + this.formatDate());
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("Moloney Demand Export", { "leaderboard": this.title })
            } catch (err) {
                console.error(err);
            }

        },
        sumField(key) {
            return this.all_data.reduce((a, b) => a + (Number(b[key]) || 0), 0)
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
                this.$nextTick(this.refreshData);
            }
        },
        'filters.LeadType': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'filters.OrderType': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'options': {
            deep: true,
            handler(newV) {
                /*var g = this;
                if (!this.firstPull) {
                    this.$vuetify.goTo('#' + this.entity + '_datatable', {
                        duration: 250,
                        easing: 'easeOutCubic',
                    });
                }
                this.$nextTick(g.refreshData)
                */
            }
        },
    },
    components: {
        QFilters,
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.grey_row {
    background-color: #f7f7f7;
}

.lead-order-table {
    font-size: 12px;
}

.lead-order-line-table {
    background-color: #f7f7f7;
}

.lead-order-line-table table td {
    font-size: .9em;
    width: 10%;
}
</style>
<style>
.lead-divider {
    margin: 0 6px;
}
</style>