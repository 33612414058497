<template>
  <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
      <q-agent-lead-order-submenu v-if="hasRole(['SuperAdmin', 'Staff', 'Exec'])" has-demo v-on:demo="startDemo()"></q-agent-lead-order-submenu>
      <q-my-leads-submenu v-else></q-my-leads-submenu>
      <v-row class="mx-5">
          <v-col cols=12 class="text-center">
              <LighthouseLeadOrdersTable :agent="user.Agent" />
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import LighthouseLeadOrdersTable from './LighthouseLeadOrdersTable';
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';
import QMyLeadsSubmenu from '@/components/navigation/Submenus/QMyLeadsSubmenu.vue';

export default {
  components: {
      LighthouseLeadOrdersTable,
      QAgentLeadOrderSubmenu,
      QMyLeadsSubmenu
  },
}
</script>
