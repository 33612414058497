<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1 text-left" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 pl-4">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">Approve Lead Credit Requests
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            dense
                            dark
                            append-icon="icon-q-search"
                            label="Search"
                            single-line
                            hide-details
                            @keyup="maybeSearch"
                            @click:append="refreshData()"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0 nowrap" :footer-props="{itemsPerPageOptions:[50,100,200,300]}" :loading="loading_data" :options.sync="options" show-expand :expanded.sync="expanded" :server-items-length="total_num" >
                <template v-slot:body.append>
                    <tr>
                        <td></td>
                        <td>
                            <v-btn @click="saveApprovals" color="primary">Save</v-btn>
                            <v-progress-linear v-if="saving" class="mt-2" color="primary" indeterminate></v-progress-linear>
                        </td>
                    </tr>
                </template>
                <template v-slot:item.AgentName="{ item }">
                    <span class="nowrap">
                        <router-link :to="'/agents/' + item.AgentCode">{{item.AgentName}}</router-link>
                    </span>
                </template>
                <template v-slot:item.Amount="{ item }">
                    <span>{{ formatLongCurrency(item.Amount) }}</span>
                </template>
                <template v-slot:item.CreateDate="{ item }">
                    <span>{{ formatDate(item.CreateDate) }}</span>
                </template>
                <template v-slot:item.ID="{ item }">
                    <span v-if="item.TransactionType == 'Denied'">
                        <v-icon color="red">fas fa-times-circle</v-icon>
                    </span>
                    <span v-else-if="item.TransactionType == 'Credit'">
                        <v-icon color="green">fas fa-check</v-icon>
                    </span>
                    <span v-else>
                        <v-select dense label="" :items="[{text:'Approve',value:{ID:item.ID, Status:'Approve'}}, {text:'Deny',value:{ID:item.ID, Status:'Deny'}}]" v-model="lead_credit_requests[item.index]"></v-select>
                    </span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5 text-left">
                            <div class="pb-4" v-if="item.Notes">
                                <pre>{{item.Notes}}</pre>
                            </div>
                        </td>
                    </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QApproveLeadCreditRequestsDataTable",
    mixins: [QDataTableMixin],
    props: [],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead',
            filters: { 'Status': ["Pending", "Denied", "Credit"], 'LeadType': "-All-" },
            options: { sortBy: ['CreateDate'], sortDesc: [true] },
            the_data: [],
            loading_data: false,
            log_available_filters: null,
            approving: false,
            lead_credit_requests: [],
            expanded:[],
            saving: false,
            total_num: 0
        }
    },
    created() {
        this.loadData = debounce((opt) => {
            this.loading_data = true;
            const filters = {
                attr: 'LeadAssignment,AgentName,AgentCode',
                Status: this.filters.Status.join(","),
            }

            QuilityAPI.getLeadCreditRequests(filters, opt).then((json) => {
                this.loading_data = false;
                this.the_data = json.data;
                this.the_data.forEach((item, ix) => item.index = ix)
                this.total_num = json.meta.total
            }).catch((error) => {
                this.loading_data = false;
                this.showError(error);
            });
        }, 500)
    },
    mounted: function() {
        this.refreshData();
    },
    computed: {
        filter_keys: function() {
            return ['LeadType', 'Status']
        },
        all_data: function() {
            return this.the_data;
        },
        the_headers: function() {
            return [{
                text: 'Approve/Deny',
                value: 'ID',
                align: "center",
                sortable: false,
            }, {
                text: 'Date',
                value: 'CreateDate',
                align: "left",
                sortable: true,
            }, {
                text: 'Agent',
                value: 'AgentName',
                align: "left",
                sortable: false,
            }, {
                text: 'Label',
                value: 'TransactionLabel',
                align: "left",
                sortable: false,
            },{
                text: 'Amount',
                value: 'Amount',
                align: "right",
                sortable: false,
            }, {
                text: 'Lead Level',
                value: 'LeadAssignment.LeadLevel',
                align: "left",
                sortable: false,
            }, {
                text: 'LeadType',
                value: 'LeadAssignment.LeadType',
                align: "left",
                sortable: false,
            }, {
                text: 'Status',
                value: 'LeadAssignment.LeadStatus',
                align: "left",
                sortable: false,
            }]
        },
        available_filters: function() {
            return this.log_available_filters;
        },
        total_items: function() {
            return this.total_num;
        },
    },
    methods: {
        goToTop: function() {
            this.$vuetify.goTo('#lead_datatable', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        filterFor(key, v, data) {
            var d = []
            if (v == "-All-") {
                return data
            }
            data.forEach(function(item) {
                if (item[key] == v) {
                    d.push(item)
                }
            })
            return d;
        },
        refresh() {
            this.reset();
        },
        reset() {
            this.the_data = []
            this.lead_credit_requests = []
            this.refreshData();
            //this.filters = { 'LastChangeBy': "-All-", 'LeadType': "-All-", 'Status': "-All-" };
        },
        saveApprovals(item, approved) {
            var g = this
            g.saving = true;
            QuilityAPI.approveDenyLeadCreditRequests(this.lead_credit_requests).then(function(json) {
                g.saving = false;
                g.reset()
            }).catch(function(e) {
                g.saving = false;
                g.showError(e);
            });
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler: function(newV) {
                this.$nextTick(this.reload)
            }
        }
    },
    components: {

    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}
</style>