<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-lead-order-submenu has-demo v-on:demo="startDemo()"></q-agent-lead-order-submenu>
        <v-row class="mx-5">
            <v-col cols=12 class="text-center">
                <q-lead-allocation-data-table :no-actions="true" ref="allocation_datatable" :agent-code="this.agent_code" title="Lead Allocation" key="agent_detail_allocation"></q-lead-allocation-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QLeadAllocationDataTable from '@/components/datatables/LeadOrders/QLeadAllocationDataTable.vue'
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';

export default {
    data() {
        return {
            //search: '',
        }
    },
    computed : {
        agent_code : function(){
            if (this.hasRole("SuperAdmin", "Staff", "Exec")){
                return null
            }
            return this.user.Agent.AgentCode
        }
    },
    components: {
        QLeadAllocationDataTable,
        QAgentLeadOrderSubmenu
    },
    methods: {

    },

}

</script>
