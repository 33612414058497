<template>
    <div>
        <v-card flat>
            <v-container fluid class="ma-0 pa-0" id="account_datatable">
                <v-row class="q_leads_1">
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">
                            {{ title }}
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <!--<v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>//-->
                </v-row>
                <v-row class="d-flex justify-start" v-show="showFilters">
                    <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :show-reset="true"></q-filters>
                </v-row>
                <v-row class="d-flex justify-start pa-2" id="form">
                    <v-col cols="2">
                        <v-select v-if="available_filters" label="Transaction Category" :items="category_options" v-model="filters.TransactionCategory"></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-select v-if="available_filters" label="Transaction Type" :items="type_options" v-model="filters.TransactionType"></v-select>
                    </v-col>
                    <v-col cols="2">
                        <q-date-picker label="Start Date" v-model="startDate"></q-date-picker>
                    </v-col>
                    <v-col cols="2">
                        <q-date-picker label="End Date" v-model="endDate"></q-date-picker>
                    </v-col>
                    <v-col cols="2">
                        <v-btn @click="applyDateRange">Apply</v-btn>
                    </v-col>
                </v-row>
                <v-data-table :mobile-breakpoint="2" :items-per-page.sync="rows" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" :search="search" show-expand :expanded.sync="expanded">
                    <!--adds view ticket icon -->
                    <template v-slot:item.Balance="{ item }">
                        {{ formatLongCurrency(item.Balance) }}
                    </template>
                    <template v-slot:item.Amount="{ item }">
                        <strong v-if="item.Amount < 0" class="red--text">{{ formatLongCurrency(item.Amount) }}</strong>
                        <strong v-else>{{ formatLongCurrency(item.Amount) }}</strong>
                    </template>
                    <template v-slot:item.TransactionType="{ item }">
                        <div>
                            {{item.TransactionType}}
                            <v-btn class="ml-4" v-if="hasPermission('manage:AgentAccount') && item.ProcessorTransactionID != '' && item.ProcessorTransactionID != null && item.TransactionType != 'Credit Card Refund'"x-small @click="showRefundDialog(item)">Refund</v-btn>
                        </div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                            <div class="pb-4" v-if="item.ProcessorTransactionID">
                                Transaction ID : {{item.ProcessorTransactionID}}
                            </div>
                            <div class="pb-4" v-if="item.Notes">
                                <pre>{{item.Notes}}</pre>
                            </div>
                            <div class="pb-4" v-if="item.OrderNumber">Lead Order #{{item.OrderNumber}}</div>
                            <div class="pb-4" v-if="item.LeadAssignmentID > 0">
                                {{item.LeadAssignment.LeadCode}} <br>
                                {{item.LeadAssignment.FirstName}} {{item.LeadAssignment.LastName}}<br>
                                {{item.LeadAssignment.City}}, {{item.LeadAssignment.State}} {{item.LeadAssignment.Zip}}
                            </div>
                            <pre v-if="item.TransactionPayload">{{ JSON.stringify(item.TransactionPayload, null, 2) }}</pre>
                        </td>
                    </template>
                    <template v-slot:body.append>
                        <tr>
                            <td colspan="12">
                                <v-btn @click="downloadData" outlined color="#0083eb">Export</v-btn>
                                <v-progress-linear v-if="loading" class="mt-2" color="primary" indeterminate></v-progress-linear>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "../QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import moment from 'moment'
import QDatePicker from '@/components/utils/QDatePicker.vue'

export default {
    name: "QAgentAccountTransactionDataTable",
    props: [],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'account',
            //use this to "preset" a filter
            filters: {
                'TransactionCategory' : '-All-', 
                'TransactionType' : '-All-', 
                //'TransactionLabel' : '-All-'
            },
            'startDate': moment().startOf('month').format('YYYY-MM-DD'),
            "endDate": moment().endOf('month').format('YYYY-MM-DD'),
            options: {
                'sortBy': ['CreateDate'],
                'sortDesc': [true]
            },
            expanded: [],
            the_data: [],
            total_num: 0,
            data_filters: null,
        }
    },
    mounted: function() {
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['TransactionCategory', 'TransactionType'];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Created',
                    value: 'CreateDate',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'AgentName',
                    value: 'AgentName',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Label',
                    value: 'TransactionLabel',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Type',
                    value: 'TransactionType',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Category',
                    value: 'TransactionCategory',
                    sortable: true,
                    align: "left"
                },
                {
                    text: 'Amount',
                    value: 'Amount',
                    sortable: true,
                    align: "right"
                }
            ]
        },
        available_filters: function() {
            return this.data_filters
        },
        category_items: function() {
            return [
                "Manual Adjustment",
                "Other",
                "Leads",
            ]
        },
        category_options: function() {
            var r = this.available_filters.TransactionCategory;
            r.push({
                'text': '-All-',
                'value': '-All-'
            })
            return r.sort();
        },
        type_options: function() {
            var r = this.available_filters.TransactionType;
            r.push({
                'text': '-All-',
                'value': '-All-'
            })
            return r.sort();
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            if (this.data_filters == null) {
                opt.needAvailableFilters = true
            }
            var filters = {
                ...this.filters,
                startDate : this.startDate,
                endDate : this.endDate,
            }
            QuilityAPI.getLedgerTransactions(filters, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                if (typeof json.meta != 'undefined'){
                    g.total_num = json.meta.total
                }
                if (typeof json.meta != 'undefined' && typeof json.meta.filters != 'undefined') {
                    g.data_filters = json.meta.filters
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                //g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        refresh: function() {
            this.the_data = [];
            this.refreshData();
        },
        goToTop: function() {
            this.$vuetify.goTo('#account_datatable', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        applyDateRange: function(){
            this.options.page = 1
            this.refresh();
        },
        downloadData: debounce(function() {
            var endpoint = '/api/private/account_transactions';
            var params = {
                ...this.options,
                ...this.filters,
                startDate : this.startDate,
                endDate : this.endDate,
                'itemsPerPage': "csv",
                'attr' : 'AgentName,AgentCode'
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': "Transaction Export"
            })
        })
    },
    watch: {
        'options':  {
            deep: true,
            handler(newV, oldV) {
                this.goToTop();
                this.refreshData();
            }
        },
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
            }
        }
    },
    components: {QDatePicker}
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>