<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-leads-submenu has-demo v-on:demo="startDemo()"></q-my-leads-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-lead-data-table v-if="user.Agent.Status == 'Active'" :agent="user.Agent" :rows="10" title="My Leads">
                </q-lead-data-table>
                <div v-else>
                    <v-alert color="orange">
                        Sorry. You cannot view leads until your account is "Active". Please contact your Agency Owner to re-activate your account.
                    </v-alert>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QLeadDataTable from '@/components/datatables/QLeadDataTable.vue';
import QMyLeadsSubmenu from '@/components/navigation/Submenus/QMyLeadsSubmenu.vue';

export default {
    data() {
        return {
            search: '',
        }
    },
    components: {
        QLeadDataTable,
        QMyLeadsSubmenu
    },
    methods: {
        'startDemo': function() {
            this.$tours['DemoLeads'].start()
        },
    },

}

</script>
