//make sure you update the back end pricing too! in /app/Services/AgentAccountService.php
export default {
    "IA": {
        "0": 54.56,
		"70": 54.56,
		"75": 54.56,
		"80": 54.56,
		"85": 57.97,
		"90": 61.38,
		"95": 64.79,
		"100": 68.20,
		"105": 71.61,
		"110": 75.02,
		"115": 78.43,
		"120": 81.84,
		"125": 85.25,
		"130": 88.66,
		"140": 88.66,
    },
    "DL": {
        "0": 54.56,
		"70": 54.56,
		"75": 33.39,
		"80": 35.61,
		"85": 37.84,
		"90": 40.06,
		"95": 42.29,
		"100": 44.52,
		"105": 46.74,
		"110": 48.97,
		"115": 51.19,
		"120": 53.42,
		"125": 55.75,
		"130": 58.07,
		"140": 61.88,
    },
    "DLHA": {
        "LHMP" : {
            '0' :  32.79,
            '70' : 32.79,
            '75' : 32.79,
            '80' : 32.79,
            '85' : 34.84,
            '90' : 36.89,
            '95' : 38.94,
            '100' : 40.99,
            '105' : 43.04,
            '110' : 45.09,
            '115' : 47.14,
            '120' : 49.19,
            '125' : 51.24,
            '130' : 53.29,
            '140' : 53.29,
        },
        "LHFE" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHDFL" : {
            '0' : 45.00,
            '70' : 45.00,
            '75' : 45.00,
            '80' : 45.00,
            '85' : 45.00,
            '90' : 45.00,
            '95' : 45.00,
            '100' : 45.00,
            '105' : 45.00,
            '110' : 45.00,
            '115' : 45.00,
            '120' : 45.00,
            '125' : 45.00,
            '130' : 45.00,
            '140' : 45.00,
        },
        "LHGL" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHRS" : {
            '70' : 149.00,
            '75' : 149.00,
            '80' : 149.00,
            '85' : 149.00,
            '90' : 149.00,
            '95' : 149.00,
            '100' : 149.00,
            '105' : 149.00,
            '110' : 149.00,
            '115' : 149.00,
            '120' : 149.00,
            '125' : 149.00,
            '130' : 149.00,
            '140' : 149.00,
        },
        "LHIB" : {
            '70' : 49.00,
            '75' : 49.00,
            '80' : 49.00,
            '85' : 49.00,
            '90' : 49.00,
            '95' : 49.00,
            '100' : 49.00,
            '105' : 49.00,
            '110' : 49.00,
            '115' : 49.00,
            '120' : 49.00,
            '125' : 49.00,
            '130' : 49.00,
            '140' : 49.00,
        },
        "LHIUL" : {
            '0' : 45.00,
            '70' : 45.00,
            '75' : 45.00,
            '80' : 45.00,
            '85' : 45.00,
            '90' : 45.00,
            '95' : 45.00,
            '100' : 45.00,
            '105' : 45.00,
            '110' : 45.00,
            '115' : 45.00,
            '120' : 45.00,
            '125' : 45.00,
            '130' : 45.00,
            '140' : 45.00,
        },
    },
    "LH": {
        "LHMP" : {
            '0' :  32.79,
            '70' : 32.79,
            '75' : 32.79,
            '80' : 32.79,
            '85' : 34.84,
            '90' : 36.89,
            '95' : 38.94,
            '100' : 40.99,
            '105' : 43.04,
            '110' : 45.09,
            '115' : 47.14,
            '120' : 49.19,
            '125' : 51.24,
            '130' : 53.29,
            '140' : 53.29,
        },
        "LHFE" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHDFL" : {
            '0' : 45.00,
            '70' : 45.00,
            '75' : 45.00,
            '80' : 45.00,
            '85' : 45.00,
            '90' : 45.00,
            '95' : 45.00,
            '100' : 45.00,
            '105' : 45.00,
            '110' : 45.00,
            '115' : 45.00,
            '120' : 45.00,
            '125' : 45.00,
            '130' : 45.00,
            '140' : 45.00,
        },
        "LHGL" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHRS" : {
            '70' : 149.00,
            '75' : 149.00,
            '80' : 149.00,
            '85' : 149.00,
            '90' : 149.00,
            '95' : 149.00,
            '100' : 149.00,
            '105' : 149.00,
            '110' : 149.00,
            '115' : 149.00,
            '120' : 149.00,
            '125' : 149.00,
            '130' : 149.00,
            '140' : 149.00,
        },
        "LHIB" : {
            '70' : 49.00,
            '75' : 49.00,
            '80' : 49.00,
            '85' : 49.00,
            '90' : 49.00,
            '95' : 49.00,
            '100' : 49.00,
            '105' : 49.00,
            '110' : 49.00,
            '115' : 49.00,
            '120' : 49.00,
            '125' : 49.00,
            '130' : 49.00,
            '140' : 49.00,
        },
        "LHIUL" : {
            '0' : 45.00,
            '70' : 45.00,
            '75' : 45.00,
            '80' : 45.00,
            '85' : 45.00,
            '90' : 45.00,
            '95' : 45.00,
            '100' : 45.00,
            '105' : 45.00,
            '110' : 45.00,
            '115' : 45.00,
            '120' : 45.00,
            '125' : 45.00,
            '130' : 45.00,
            '140' : 45.00,
        },
    },
    "CIA" : {
        '0' : 20.41,
        '70' : 20.41,
        '75' : 20.41,
        '80' : 20.41,
        '85' : 21.69,
        '90' : 22.96,
        '95' : 24.24,
        '100' : 25.51,
        '105' : 26.79,
        '110' : 28.06,
        '115' : 29.34,
        '120' : 30.61,
        '125' : 31.89,
        '130' : 33.17,
        '140' : 33.17,
    },
    "CI" : {
        '0' : 20.41,
        '70' : 20.41,
        '75' : 20.41,
        '80' : 20.41,
        '85' : 21.69,
        '90' : 22.96,
        '95' : 24.24,
        '100' : 25.51,
        '105' : 26.79,
        '110' : 28.06,
        '115' : 29.34,
        '120' : 30.61,
        '125' : 31.89,
        '130' : 33.17,
        '140' : 33.17,
    },
    "DX": {
        "0": 9.00,
        "70": 9.00,
        "75": 9.00,
        "80": 9.00,
        "85": 9.00,
        "90": 9.00,
        "95": 9.00,
        "100": 9.00,
        "105": 9.00,
        "110": 9.00,
        "115": 9.00,
        "120": 9.00,
		"125": 9.00,
		"130": 9.00,
        "140": 9.00,
    },
    "Recruiting": {
        "Licensed": {
            "0": 25.00,
            "70": 25.00,
            "75": 25.00,
            "80": 25.00,
            "85": 25.00,
            "90": 25.00,
            "95": 25.00,
            "100": 25.00,
            "105": 25.00,
            "110": 25.00,
            "115": 25.00,
            "120": 25.00,
			"125": 25.00,
			"130": 25.00,
            "140": 25.00,
        },
        "Unlicensed": {
            "0": 20.00,
            "70": 20.00,
            "75": 20.00,
            "80": 20.00,
            "85": 20.00,
            "90": 20.00,
            "95": 20.00,
            "100": 20.00,
            "105": 20.00,
            "110": 20.00,
            "115": 20.00,
            "120": 20.00,
			"125": 20.00,
			"130": 20.00,
            "140": 20.00,
        }
    },
	"DEA": {
        '0' : 8.58,
        '70' : 8.58,
        '75' : 8.58,
        '80' : 8.58,
        '85' : 9.12,
        '90' : 9.65,
        '95' : 10.19,
        '100' : 10.73,
        '105' : 11.26,
        '110' : 11.80,
        '115' : 12.34,
        '120' : 12.87,
        '125' : 13.41,
        '130' : 13.95,
        '140' : 13.95,
	},
    "AE": {
        '0' : 8.58,
        '70' : 8.58,
        '75' : 8.58,
        '80' : 8.58,
        '85' : 9.12,
        '90' : 9.65,
        '95' : 10.19,
        '100' : 10.73,
        '105' : 11.26,
        '110' : 11.80,
        '115' : 12.34,
        '120' : 12.87,
        '125' : 13.41,
        '130' : 13.95,
        '140' : 13.95,
	},
    "AA": {
        '0' : 8.58,
        '70' : 8.58,
        '75' : 8.58,
        '80' : 8.58,
        '85' : 9.12,
        '90' : 9.65,
        '95' : 10.19,
        '100' : 10.73,
        '105' : 11.26,
        '110' : 11.80,
        '115' : 12.34,
        '120' : 12.87,
        '125' : 13.41,
        '130' : 13.95,
        '140' : 13.95,
	}
}